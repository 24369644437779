// Normalize Styles
@import '../../node_modules/normalize.css/normalize.css';

// Import Modules
@import 'mui.min.css';
@import "hint.min.css"

dark-text = #333
dark-grey-text = #464646
grey-text = #666666
light-text = white

light-background = #fbfbfb
middle-background = #ebebeb
dark-background = #555

blue-color = #169cd9
pink-color = #ff4081
red-color = #d32f2f
dark-pink-color = #bd3550

// Box sizing partial styles
// Apply a natural box layout model to all elements
*,
*:before,
*:after
  -moz-box-sizing border-box
  -webkit-box-sizing border-box
  box-sizing border-box

.yeogurt-info
  text-align center
  max-width 500px
  margin-left auto
  margin-right auto

.version
  font-size 14px
  font-family Menlo, Monaco, Consolas, "Courier New", monospace
  position absolute
  bottom 10px
  right 10px

.bottomlogo
  margin-top 16px

// Styling on warning for users on IE7 or below
.browsehappy
  text-align center
  margin 0
  background #fff8e2
  color #000
  padding 0.5em 0


body
  background-color light-background
  font-family "proxima-nova"

.align-right
  text-align right

.align-left
  text-align left

.align-center
  text-align center

.mui-container
  max-width 1200px !important

.header
  background-color rgb(85, 85, 85)
  width 100%
  margin 0
  padding 12px 0
  position fixed
  z-index 10
  transition background-color .5s
  .primary-buttons
    &.for-small
      display flex
      justify-content flex-end
    a
      display block
      margin-right 16px
      text-decoration none
      &.start-for-free
        font-weight 400
        background pink-color
        color white
        font-size 16px
        padding 5px 30px
        border-radius 4px
      &.sign-in
        font-weight 400
        background white
        color dark-text
        font-size 16px
        padding 5px 30px
        border-radius 4px
        &:hover
          color dark-text
  .inner-content
    display flex
    height 80px
    justify-content space-between
    align-items center
    max-width 1200px
    margin 0 auto
    padding 0 64px 0 0
    .navmenu
      flex-grow 1
      display flex
      justify-content flex-end
      align-items center
      &.left
        justify-content flex-start
        a
          margin-left 64px
          display block
          color light-text
          text-decoration none
          font-weight 700
          font-size 1.2em
        @media screen and (max-width: 960px)
          margin-left 16px


.header
  &.transparent
    background transparent
    position absolute
    .inner-content
      .logo
        background-image url(../images/logo_short.png)
    #mobile-menu
      background-color white
      float right
      margin-right 16px
      width 140px
      border-radius 4px
      a
        color dark-text
        &:hover
          color blue-color
  &.white
    background-color #2196f3
    background-image url('../images/mark.png')
    .inner-content
      .logo
        background-image url(../images/logo_short.png)

.invalid-email
  color red-color
  margin-top 10px
  margin-bottom 10px

.logo
  display block
  width 195px
  height 40px
  background-image url(../images/logo.png)
  background-position left
  background-repeat no-repeat
  background-size contain



#mobile-menu
  margin-top 8px
  a
    display block
    text-align right
    color white
    padding 8px 12px
    font-size 1.2em
  &:hover
    text-decoration none
    color blue-color

a.iphd-btn
  display inline-block
  padding 16px 32px
  border-radius 30px
  text-decoration none
  font-size 17px
  &.primary
    font-weight bold
    background blue-color
    color white
    &:hover
      background-color dark-background
  &.secondary
    background white
    color dark-grey-text
    &:hover
      background-color dark-background
      color white
  &:hover
    text-decoration none

.hero
  background-color white
  background-size cover
  background-repeat no-repeat
  background-position top center
  padding-bottom 150px
  padding-top 140px
  padding-left 32px
  padding-right 32px
  color white
  .content
    z-index 1
    position relative
  .action-row
    ul
      margin 0
      padding 0
      list-style none
      li
        display inline-block
        margin 0 8px
        &:first-child
          margin-left 0
        &:last-child
          margin-right 0
  .mainTitle
    font-weight 600
    overflow visible
    font-size 35px
    line-height 35px
    color white
  .tagLine
    margin-top 15px
    font-weight 500
    font-size 26px
    color white
  .postTagline
    font-size 24px
    margin-top 64px
  &.light
    background white
    color dark-grey-text
  &.full-bg-video
    background grey-text
    position relative
    overflow hidden
    .video-wrap
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      .video-wrap-inner
        position relative
        width 100%
        height 100%
        .video-overlay
          position absolute
          width 100%
          height 100%
          background rgba(0,0,0,0.3)
  &.homepage-variant
    padding-bottom 0
    .action-row
      text-align left
    .homepage-video-wrap
      video
        display block
    .mainTitle
      margin-top 0
      font-weight 700
      line-height 1
      font-size 56px
      color grey-text
      text-align left
    .tagLine
      color grey-text
      text-align left
      font-weight 300
      line-height 1
      font-size 28px
    .postTagline
      color white
      font-size 24px
    .hero-slide-down
      color white
      margin-top 32px
    .variant-track
      background grey-text
      padding-bottom 64px
      color #fff
  &.real-estate
    background-image url(../images/iplayerhd-real-estate-hero.jpg)
    .inner-wrap
      padding 2%
      background rgba(0, 0, 0, 0.4)
      border-radius 2px
      -moz-border-radius 2px
      -webkit-border-radius 2px
      border 1px solid rgba(0,0,0,.09)

.leftMainContent
  text-align left
  padding 40px 0 0 0

@media screen and (max-width: 990px)
  .leftMainContent
    text-align center
    padding-right inherit
    padding-bottom 32px !important

.central
  text-align center

.section
  padding 80px 0
  h1
    font-size 42px
    font-weight bold
    text-align center
    line-height 56px
    &.title
      margin-top 100px
      font-size 48px
      margin-bottom 100px
      &.smaller
        font-size 30px
        font-weight 600
        margin-bottom 60px
  > h2
    font-size 36px
    font-weight bold
    text-align center
    line-height 48px
  > h3
    font-size 30px
    font-weight 600
    text-align center
    line-height 48px
  .explainer
    padding 30px
    text-align center
    max-width 620px
    margin 0 auto
    h3
      font-size 28px
      font-weight medium
      line-height 47px
    p
      font-size 18px
      line-height 32px
    a
      font-size 18px

  &.small
    padding 40px 0

.light
  background light-background
  color dark-text
  a:link, a:visited
    color blue-color
    text-decoration none
  a:hover
    color pink-color
  a.mui-btn:link, a.mui-btn:visited
    color white
    text-decoration inherit

.light.middle
  background middle-background

.dark
  background dark-background
  color light-text
  a:link, a:visited
    color light-text
    text-decoration none
  a:hover
    color blue-color
  a.mui-btn:link, a.mui-btn:visited
    color white
    text-decoration inherit
  .feature-text
    h2
      color white
    p
      color light-text
  .feature-icon-container
    filter brightness(1000%)



img.feature
  display inline-block

.feature-icon-container
  min-height 85px
  margin auto

.feature-text
  margin-top 50px
  text-align center
  max-width 360px
  margin-left auto
  margin-right auto
  h2
    text-transform uppercase
    font-size 24px
    font-weight 600
    color dark-text
  p
    font-size 21px
    color grey-text
  &.real-estate
    text-align center
    margin-top 20px
    h2
      text-transform none
      font-size 21px
      font-weight 600
      color dark-text
    p
      font-size 17px
      color dark-gray-text


.mui-btn--primary
  background-color blue-color
  font-size 18px
  min-height 50px
  line-height 50px

.mui-btn--accent
  font-size 18px
  min-height 50px
  line-height 50px

.italic
  font-style italic
  margin-top 70px
  margin-bottom 70px

.title
  margin-top 100px
  font-size 48px
  margin-bottom 100px
  &.smaller
    font-size 30px
    font-weight 600
    margin-bottom 60px

.learn-more
  margin-bottom 120px
  margin-top 80px
  font-size 24px

.links
  margin-top 30px

.pricingplan
  max-width 300px
  margin-left auto
  margin-right auto
  background-color blue-color
  color light-text
  padding-bottom 40px
  border-radius 2px
  p
    display inline-block
  &.enterprise
    background-color pink-color
    & .pricing-button:hover a
      color pink-color
  .name
    text-transform uppercase
    font-size 22px
    padding-top 30px
  .price-info
    min-height 130px
    img
      margin-top 20px
  .dollar
    vertical-align top
    line-height 64px
    font-size 18px
  .price
    font-size 84px
  .month
    margin-left -7px
    font-size 18px
  .pricing-feature
    font-size 16px
    margin-top 13px
    margin-bottom 13px
    &.hint--large
      cursor pointer
  .mui-divider
    background-color light-text
    height 1px
    width 60%
    margin-left auto
    margin-right auto
  .pricing-button
    font-size 18px
    margin-top 30px
    margin-left 40px
    margin-right 40px
    border 1px light-text solid
    border-radius 8px
    cursor pointer
    a
      line-height 48px
      color light-text
    &:hover
      background-color light-text
      a
        color blue-color


.try-me
  display inline-block
  font-size 18px
  margin-top 60px
  border 1px blue-color solid
  border-radius 16px
  padding-left 20px
  padding-right 20px
  padding-top 10px
  p
    line-height 28px
    color blue-color !important
    text-transform uppercase
    text-align center

.copyright
  text-align center
  margin 30px 0
  color #9b9b9b

.accent
  color blue-color

.result
  color blue-color
  font-size 20px
  margin 20px

.explainerParagraphs
  text-align left
  ol
    font-size 17px
    text-align left
    li
      h3
        font-size 21px
        font-weight bold
  section
    margin 0 auto
    max-width 960px
    margin-bottom 40px
    p:first-child
      text-transform uppercase
      font-weight 600
      font-size 27px
      margin-bottom 20px
    p
      font-size 17px
      line-height 26px

.pink
  color dark-pink-color

.contactForm
  input, textarea
    border 2px solid #ccc
    border-radius 6px
    padding 10px
  input
    height 48px !important
  textarea
    height 192px !important
  button
    width 100%

.contactUsForSupport
  h3
    text-transform uppercase
  h4
    line-height 16px
    font-weight 600
  margin-bottom 120px

//range inputs
input[type=range]
  -webkit-appearance none
  margin 18px 0
  width 100%

input[type=range]:focus
  outline none
  &::-webkit-slider-runnable-track
    background blue-color
  &::-ms-fill-lower
    background blue-color
  &::-ms-fill-upper
    background blue-color

input[type=range]::-webkit-slider-runnable-track
  width 100%
  height 2px
  cursor pointer
  animate 0.2s
  background blue-color

input[type=range]::-moz-range-track
  width 100%
  height 2px
  cursor pointer
  animate 0.2s
  background blue-color

input[type=range]::-ms-track
  width 100%
  height 2px
  cursor pointer
  animate 0.2s
  background blue-color

input[type=range]::-webkit-slider-thumb
  box-shadow 0px 0px 15px #bbb, 0px 0px 20px #ddd
  border 0px
  height 40px
  width 40px
  border-radius 30px
  background blue-color
  cursor pointer
  -webkit-appearance none
  margin-top -20px

input[type=range]::-moz-range-thumb
  box-shadow 0px 0px 15px #bbb, 0px 0px 20px #ddd
  border 0px
  height 40px
  width 40px
  border-radius 30px
  background blue-color
  cursor pointer
  -webkit-appearance none
  margin-top -20px

input[type=range]::-ms-thumb
  box-shadow 0px 0px 15px #bbb, 0px 0px 20px #ddd
  border 0px
  height 40px
  width 40px
  border-radius 30px
  background blue-color
  cursor pointer
  -webkit-appearance none
  margin-top -20px


input[type=range]::-ms-fill-lower
  background blue-color

input[type=range]::-ms-fill-upper
  background blue-color

.features
  p, li
    text-align left
    color grey-text
    font-size 18px
    font-weight 600
  .title
    margin-bottom 50px

.features-list
  font-size 20px
  margin-top 100px
  margin-bottom 100px

.view-all-features
  margin-top 100px
  margin-bottom 100px

.feature
  padding-top 30px
  padding-bottom 30px

.videoWrapper
  position relative
  padding-bottom 56.25% !important
  height 0

  iframe
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border 0

.framecatch-headline
  margin-top 80px
  a
    font-size 46px
    text-decoration none
    color white

.paymentInfo li strong
  color #169cd9

.note
  margin-top 20px
  font-style italic

.backToTop
  margin-top 80px
  font-size 30px

.compare
  font-size 20px

.compare-height
  height 100px

.pricing-button-no-overages
  margin-top 79px

.trial-pricing-button
  margin-top 128px

@media screen and (max-width: 1500px)
  .hero
    &.full-bg-video
      padding-top 100px

@media screen and (max-width: 1400px)
  .hero
    &.full-bg-video
      padding-top 100px
      .hero-slide-down
        margin-top 32px

@media screen and (max-width: 1300px)
  .hero
    &.full-bg-video
      padding-bottom 64px
      padding-top 100px
      .mainTitle
        font-size 48px
      .tagLine
        font-size 32px
      .hero-slide-down
        margin-top 32px

@media screen and (max-width: 1200px)
  .leftMainContent
    padding 0
  .autoPaddingTop
    padding-top 28px
  .header.white
    .logo
      background-image url(../images/logo_dark_small.png)
  .logo
    width 147px
    height 30px
    background-image url(../images/logo_small.png)
  .navmenu
    h4
      margin 12px
      margin-top 21px
    .user-menu
      li
        a
          padding-top 21px
          padding-bottom 10px
        .hamburger
          img
            margin-top -3px
  .hero
    &.full-bg-video
      padding-top 100px
      .mainTitle
        font-size 36px
      .tagLine
        font-size 24px
      .postTagline
        font-size 18px
        margin-top 32px
      .hero-slide-down
        margin-top 32px
    &.homepage-variant
      .mainTitle
        font-size 48px
      .tagLine
        font-size 24px
    .mainTitle
      font-size 35px
      line-height 35px
    .tagLine
      font-size 26px
      line-height 26px
    .framecatch-headline
      a
        font-size 36px

@media screen and (max-width: 990px)
  .hero
    .action-row
      ul
        li
          margin-bottom 16px
    &.full-bg-video
      padding-top 72px
      padding-bottom 24px
    &.homepage-variant
      .action-row
        text-align center
        ul
          li
            margin-bottom 16px
      padding-top 100px
      padding-bottom 32px
      .mainTitle
        text-align center
      .tagLine
        text-align center
      .homepage-video-wrap
        margin-top 32px

@media screen and (max-width: 720px)
    .hero
      &.full-bg-video
        .tagLine
          margin-top 64px
      &.homepage-variant
        .mainTitle
          font-size 32px
    .mainTitle
      font-size 34px
    .framecatch-headline
      a
        font-size 24px



@media screen and (max-width: 440px)
  .adjust-for-smaller-screen
    font-size 16px
    padding-left 10px
    padding-right 10px

@media screen and (max-width: 350px)
  .adjust-for-smaller-screen
    font-size 14px
    padding-left 6px
    padding-right 6px

@media screen and (max-width: 1000px) and (min-width: 768px)
  .adjust-start-free-year
    font-size 16px

.nav-menu
  margin-top 10px
  button
    background transparent !important
    box-shadow none !important
  i
    font-size 32px
    line-height 50px
    color white

.white .nav-menu i
  color dark-grey-text


//544px
//768px
//992px
//1200px

#footer
  h6
    text-align left
    font-size 16px
    border-bottom 1px solid #9b9b9b
    font-weight normal
    margin-bottom 10px
    padding-bottom 5px
  ul
    list-style none
    text-align left
    margin 10px 0 30px 0
    padding 0
    li
      margin-bottom 5px
      a
        font-size 14px
        line-height 24px
        img
          margin-right 5px

.testimonial
  margin 30px 0
  padding 30px 60px
  background-size cover
  background-repeat no-repeat
  background-position top center
  background-image url(../images/iplayer_testimonials_bg.jpg)
  border-radius 2px
  -moz-border-radius 2px
  -webkit-border-radius 2px
  p
    color #fff
    font-size 21px
    &.author
      font-style italic
      font-size 17px
      margin 30px 0

#mobile-menu
  display none

#enterprise-plan
  .left-col
    border 1px solid #999999
    padding 20px 30px
    text-align left
    border-radius 2px
    -webkit-border-radius 2px
    -moz-border-radius 2px
    p
      font-size 21px
    ul
      list-style none
      margin 0
      padding 0
      li
        font-weight 300
        font-size 16px
        margin-bottom 5px
        img
          margin-right 5px
  .right-col
    padding-top 10px
    text-align left
    h2
      margin 0
      font-weight 400
      font-size 32px
    p
      margin 10px 0
      font-weight 300
      font-size 24px
      color #ccc

#about-us
  .title
    margin 30px 0 50px 0
  p
    font-size 17px
    line-height 23px
    text-align justify
    margin 30px 0
    &.caption
      margin 5px 0 20px
      text-align center
      font-size 14px
      font-style italic
  img
    max-width 100%

#paid-reasons
  padding-bottom 0
  h1.title
    margin 20px 0 60px
    text-align center

  .reason
    background-color #fbfbfb
    padding 20px 0
    &:nth-child(odd)
      background-color #ececec
    p
      font-size 17px
      &.reason-title
        font-size 24px
        line-height 36px
        margin-top 20px

.hero-slide-down
  margin-top 80px
  display inline-block
  cursor pointer
  font-size 18px
  padding-left 20px
  color white
  &:hover
    text-decoration underline
    color white


.signup-pages
  background-color #fafafa
  padding 50px 0
  .form-block
    width 100%
    height 50px
    margin 20px 0 40px
    position relative
    input
      border-radius 3px
      background #fff
      border 1px solid #ccc
      width: 100%
      height: 50px;
      color #333
      font-size 21px
      padding 0 20px
      &:disabled
        background-color #ccc

  .notice
    padding 5px
    border 1px solid #CCCCCC
    background #fff

  .social-sign-up
    border none
    background none
    display inline-block
    width 230px
    height 50px
    font-size 15px
    color #fff
    margin 8px 0 30px
    border-radius 2px
    box-shadow 0 0 2px rgba(0,0,0,.12),0 2px 2px rgba(0,0,0,.2)
    span
      float left
      line-height 45px
    img
      margin 10px 10px
      float left
    &.facebook
      background-color #3b5998
    &.google
      background url('../images/google_normal.png') center left no-repeat
      background-size contain
      padding-left 0
      padding-right 0
      box-shadow none
      border 0
      height 60px
      width 238px
      margin-left -3px
    &.google:hover
      background url('../images/google_focus.png') center left no-repeat
      background-size contain
    &.google:focus
      background url('../images/google_pressed.png') center left no-repeat
      background-size contain
      outline none
    &.google:disabled
      background url('../images/google_disabled.png') center left no-repeat
      background-size contain


  .togglePassVisibility
    outline none
    background url('../images/icon_visibility.png') center left no-repeat
    border none
    width 24px
    height 24px
    position absolute
    right 8px
    top 13px
    &.on
      background-position center right

  .form-errors
    min-height 70px
    p
      padding 8px 20px
      background #ffb3b3
      border 1px solid #b91414
      color #b91414
  .terms
    text-align center
    margin-top 50px
    color #999


.bare-header
  padding 30px
  margin 0 auto
  max-width 1440px
  .login-btn
    text-decoration none
    cursor pointer
    color #333333
    font-size 17px
    padding 8px 20px
    border 1px solid #333
    border-radius 3px
    -moz-border-radius 3px
    -webkit-border-radius 3px

.sign-up-btn
  span.loader
    img
      display none
  span
    float left
    &:first-child
      margin-left 40px
    &.loader
      display block
      width 30px
      height 30px
      margin-left 10px
  &.loading
    span.loader
      img
        display inline

#reactivateArea
  position relative
  min-height 100px
  padding 20px
  font-size 15px
  &.activated
    background-color #e3eff5
    border 1px solid blue-color

#fyfEligibility
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  background rgba(56,181,230,0.8)
  border-radius 5px
  z-index 1000
  p
    text-align center
    color #fff
    padding 0 5%
    font-size 17px
    margin-top 20px
    &:first-child
      font-size 24px
      text-transform uppercase
      margin-top 50px
  img
    width 50px
    height 50px
    margin-top 30px

.blurry
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);

#fyf-promo
  text-align left
  padding 30px
  color #333
  h2
    font-weight bold
    margin 0
    padding 0
  p
    font-size 17px
    margin-top 20px

ft-table-border-color = #999
ft-table-font-color = dark-grey-text
ft-tooltip-font-color = black
ft-tooltip-background-color = white

.tooltip
  position relative
  display inline-block
  cursor help
  border-bottom: 1px dotted ft-table-border-color;
  .tooltiptext
    visibility hidden
    width 250px
    background-color ft-tooltip-background-color
    border 1px solid ft-tooltip-font-color
    color ft-tooltip-font-color
    text-align center
    padding 12px
    border-radius 6px
    position absolute
    z-index 1
    bottom 150%
    left 50%
    margin-left -125px
    &::after
      content " "
      position absolute
      top 100%
      left 50%
      margin-left -5px
      border-width 5px
      border-style solid
      border-color ft-tooltip-background-color transparent transparent transparent
  &:hover
    .tooltiptext
      visibility visible
      text-transform none

table.ft
  margin 0 auto
  .ft-plan
    text-align center
    border 1px solid ft-table-border-color
    .ft-plan-name
      font-size 28px
      text-transform uppercase
    .ft-plan-price
      font-size 18px
  td, th
    padding 12px
    color ft-table-font-color
    min-width 220px
  tbody
    tr
      td
        border 1px solid ft-table-border-color
        font-size 13px
      td.ft-name
        border-left 0
        text-align right
        font-size 13px
        text-transform uppercase


.popup-backdrop
  background-color rgba(0,0,0,.75)
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 100

.popup-container
  position relative
  max-width 780px !important
  margin 0 auto
  padding 30px
  top 50%
  -ms-transform translateY(-50%)
  transform translateY(-50%)

.to-squeeze, .to-squeeze *
  transition all 0s ease !important

.squeeze
  height 1px !important
  width 1px !important
  margin-left -1px
  margin-top -1px

.main-popup
  cursor pointer

.mfp-close
  margin-top -40px
  margin-right -14px
  color white
  right -6px
  text-align right
  padding-right 6px
  width 100%

.main-popup img
  width 85%
  border-radius 10px

.pulsate {
  animation: hide 03s infinite alternate;
}

@keyframes hide {
  0% { transform: scale(0.9); }
  100% { transform: translateY(1); }
}

.mainCta
  border-radius 6px !important
  &.white
    background-color white
    color blue-color !important


.new-logo {
  background-image none
  width 50px
  height 100px
  padding-top 20px
}

.new-logo:before
  content url('../images/logo_short.png')
  color #fff
  font-family "proxima-nova",sans-serif
  font-size 30px
